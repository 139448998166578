html {
	height: 100%;
	margin: 0;
	padding: 0;
}

body {
	@extend html;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	background: url("/dist/img/bg_img.png") no-repeat top center;
	background-color: $pc-background;
	overflow-x: hidden;
}

* {
	box-sizing: border-box;
}

// .fancybox-wrap, .fancybox-wrap * {
//     -moz-box-sizing: content-box;
//     -webkit-box-sizing: content-box;
//     -safari-box-sizing: content-box;
//     box-sizing: content-box;
// }
a {
    color: $red-link;
}

.body-wrapper {
	min-height: 100%;
	position: relative;

	&:after {
		content: "";
		display: block;
		height: 50px;
	}
}

input {
	@include inputs;
	font-size: 14px;

	&[type="text"],
	&[type="number"],
	&[type="email"],
	&[type="search"],
	&[type="tel"],
	&[type="password"] {
		box-shadow: $input-shadow;
	}
}

input[type="search"] {
	color: #000;
}

legend {
	font-size: 16px;
	padding-bottom: 5px;
	border-bottom: $content-border;
	margin-bottom: 10px;
}

.pc-content {
	@include content-area;
	overflow: hidden;
    min-height: 320px;
	margin-bottom: 125px;
	// margin-top: 35px;
}

.bread-crumbs {
	@include content-area;
	padding: 4px 10px 6px;
	margin-top: 40px;
    color: #888;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

	a {
		font-size: 13px;
		text-decoration: none;
		color: $red-link;
		padding: 0;
		margin-right: 2px;
	}

	span {
		font-size: 13px;
	}

	.bc-name {
		color: #888;
	}
}

.title {
	font-family: $open-sans;
	@include pc-text-shadow-white;
	background: url('/dist/img/title-bg.png');
	padding: 15px;
	border-bottom: 1px solid $pc-grey;
	font-weight: bold;
	font-style: italic;
	text-transform: uppercase;
	// white-space: nowrap;
	box-sizing: border-box;
}

.content-text {
    font-size: 14px;
    margin-bottom: 4px;
}

.section_gutter {
    float: left;
    width: 100%;
    margin-top: 20px;
}

.no-display {
	display: none;
}

.modal-title {
	border-radius: $lg-border-radius $lg-border-radius 0 0;
}

.pc-modal-content,
.pc-premodal-content {
	transition: all 2s ease-in;
	box-sizing: border-box;
	padding: 15px;
    background: #fff;
    color: #000;
    text-shadow: none;
    height: auto;
    -webkit-border-radius: 0 0 $lg-border-radius $lg-border-radius;
    -moz-border-radius: 0 0 $lg-border-radius $lg-border-radius;
    border-radius: 0 0 $lg-border-radius $lg-border-radius;

    p {
    	font-size: 15px;
    }

    label {
		display: block;
		position: relative;
		max-width: 100%;
		// padding-left: 10px;
		top: 2px;
		font-weight: normal;
	}
}

.fancybox-wrap {
	// transition: all 2s ease-in;
}

.current-ad-link-text {
	display: none;
}
.pc-modal-container {
	margin-bottom: 15px;
	box-sizing: border-box;

	h4 {
		margin-top: 0;
	}
}

.vs-selections {
	display: none;
}

.pre-load {
	min-height: 180px;
}

// .pc-premodal-content {
// 	height: 200px;
// }

.select {
	display: block;
	margin-bottom: 15px;
	width: 100%;
}

.pc-red-button {
	@include pc-red-button;
}
.pc-drkgrey-button {
	@include pc-drkgrey-button;
}

.pc-grey-button {
	@include pc-grey-button;
}

.vs {
	min-width: 250px;

	.select2-container {
		display: block;
		margin-bottom: 15px;
	}
}

.select2-dropdown {
	z-index: 100000;
}

.modal-close {
	border: none;
	background: none;
	padding: 0;
	margin: 0 0 0 15px;
	font-size: 20px;
	color: #8b8b8b;
	font-style: normal;
	float: right;
}

.ip-container {
    background: #fff;
    padding: 15px;
    border-radius: 6px;

    img {
    	width: 100%;
    	min-width: 100%;
    }
}

.modal-close-image {
	@extend .modal-close;
	color: #333;
	padding-top: 4px;
	padding-right: 8px;
	font-weight: bold;
}

.fancybox-type-iframe {
	// min-width: 250px;

	.fancybox-skin {
		margin: 0 -10px -10px 0 !important;
	}
}

.added,
.sl-updated,
.nothing-in-list {
	background: #fff;
	box-sizing: border-box;
	border: 1px solid #999;
	border-radius: $lg-border-radius;
	height: 100%;
	padding: 25px;
	font-size: 21px;
	text-align: center;
	vertical-align: middle;
	font-weight: bold;
	//box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.70);
	//margin: 30px;
}

.fancybox-wrap {
    min-width: 250px;
    background-color: #fff;
    -webkit-border-radius: $lg-border-radius $lg-border-radius $lg-border-radius $lg-border-radius;
    -moz-border-radius: $lg-border-radius $lg-border-radius $lg-border-radius $lg-border-radius;
    border-radius: $lg-border-radius $lg-border-radius $lg-border-radius $lg-border-radius;
}

.v-attributes {
	min-width: 250px;
}

.cm-container {
	width: 100%;
	text-align: center;

	img {
		width: 30%;
	}
}

// .list-error {
	// background: #fff;
	// box-sizing: border-box;
	// padding: 25px;
	// border: 1px solid #999;
	// border-radius: $lg-border-radius;
// }

.updated-info {
	vertical-align: middle;
	// line-height: 50px;
	line-height: 27px;
	margin: 0;
}

.sl-button {
	font-family: $open-sans;
	@include dark-button;
	@include pc-text-shadow;
	text-transform: uppercase;
	font-weight: 600;
	border-radius: $sm-border-radius;
	color: $white-font;
	width: 156px;
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.45);
	font-size: 16px;
	padding: 0;
	margin-bottom: 10px;
	outline: none;

	.sl-inlist {
		background-color: #474748;
	}

	img {
		position: relative;
		top: 2px;
		max-width: 90%;
		max-height: 90%;
	}

	.image {
		display: inline-block;
		float: left;
		background-color: #383838;
		vertical-align: middle;
		padding: 5px 6px 5px 7px;
		border-right: 1px solid #474748;
		border-radius: 1px 0 0 1px;
	}

	.text {
		display: inline-block;
		vertical-align: middle;
		padding: 8px 10px 5px 6px;
	}
}

.sl-button-selected {
	@extend .sl-button;
}

.loader {
	@include loader(1.1em, 12em);
}

.loader-small {
	@include loader(0.7em, 5em);
}

.loader-items {
	display: block;
	color: #555;
    width: 100%;
    text-align: center;
    font-size: 16px;
    position: relative;
    top: -50px;
}

.sort-overlay {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0.4;
	background-color: #fff;
	height: 100%;
	width: 100%;
	z-index: 200;
}

.narrow-overlay {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0.4;
	background-color: #fff;
	height: 100%;
	width: 100%;
	z-index: 200;
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.vs-loader-container {
	display: none;
	position: absolute;
	height: 12em;
	width: 12em;
	left: 50%;
	top: 12%;
	margin-left: -97px;
	z-index: 100;
	overflow: hidden;
}

.make-loader {
	display: none;
	@include loader(0.3em, 2.4em);
	position: absolute;
	top: 113px;
    left: 259px;
    margin: 0;
    z-index: 100;
}

.model-loader {
	display: none;
	@include loader(0.3em, 2.4em);
	position: absolute;
	top: 156px;
    left: 259px;
    margin: 0;
    z-index: 100;
}

.paginate {
	font-family: $open-sans;
	text-align: center;
	padding: 12px 0;
	background: #f1f1f1;
	border-bottom: 1px solid $pc-grey;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 5px;
	}

	.page-link {
		text-decoration: none;
		// padding: 4px 10px;
		// border: 1px solid #333;
		// border-radius: $sm-border-radius;
		@include sm-pc-red-button;

		&:hover {
			background: #dc2825;
		}
	}

	.disabled {
		display: none;
	}

	.active {
		//@extend .disabled;
		border-radius: $sm-border-radius;
		border: none;
		padding: 3px 10px;
		font-size: $button-font-size;
		box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.45);
		color: #fff;
		background-color: #a7211f;
	}
}

.store-error {
	// background: #FF3331;
	background: #fd5252;
	padding: 25px 15px;
	color: #fff;
}

.error-message {
	padding: 0;
	margin: 0;
	@include pc-text-shadow;
	text-align: center;
	font-size: 18px;
	line-height: 25px;
}

.price-error {
	background: #fd5252;
	color: #fff;
	padding: 15px;
	@extend .error-message;

	&.left {
		text-align: left;
	}
}

.placeholdersjs {
	color: #888;
}

.filter-button-container {
	display: block;
	clear: both;
	width: 100%;
	padding: 15px;
	border-bottom: 1px solid #d8d8d8;;
}

.goto-filters {
	display: block;
	position: relative;
	font-family: "Open Sans",sans-serif;
	text-align: center;
	text-decoration: none;
	font-weight: bold;
	@include sm-pc-red-button;
}

// Small devices (tablets, 768px and up)
@media only screen and (min-width: $pc-media-small) {

	.container {
	    padding-left: 0;
	    padding-right: 0;
	    // width: 740px;
	    width: 46.250em;
	}

	.fancybox-wrap {
	    min-width: 300px;
	}

	.vs {
		min-width: 300px;
	}

	.v-attributes {
		min-width: 300px;
	}

}

// Medium devices (desktops, 992px and up)
@media only screen and (min-width: $pc-media-medium) {

	.container {
		width: 970px;
	}

	.pc-content {
		margin-top: 0;
	}

	.bread-crumbs {
		margin-top: 0;
	}

	.filter-button-container {
		display: none;
	}

	.header-button-container {
		display: inline-block;
		float: right;
		margin-top: 25px;
	}
}

// Large devices (large desktops, 1200px and up)
@media only screen and (min-width: $pc-media-large) {

	.container {
		width: 1170px;
	}

}

// Extra large devices (extra large desktops, 2000px and up)
@media only screen and (min-width: $pc-media-largest) {

	.container {
		width: 1556px;
	}

}
