.pc-v_sidebar {
    width: 100%;
}

.pc-v_content {
    width: 100%;
}

.pc-main-map {
    width: 100%;
    height: 535px;
}

.pc-ls--wrap {
    width: 100%;
    padding: 5px;
    background: $pc-grey;
    border-bottom: $content-border;
}

.pc-location-search {
    width: 100%;
}

.store-list {
    height: 535px;
    border-bottom: $content-border;
    overflow: hidden;
}

.store-list_wrap {
    height: 491px;
    overflow-y: scroll;
}

.maps-popup_heading {
    font-family: $open-sans;
    font-size: 15px;
    text-transform: uppercase;
    font-style: italic;
    color: $pc-grey-medium;
    font-weight: bold;
}

.maps-list_heading {
    font-family: $open-sans;
    font-size: 18px;
    text-transform: uppercase;
    font-style: italic;
    color: $pc-grey-medium;
    font-weight: bold;
}

.maps-list_heading-sm {
    font-family: $open-sans;
    font-size: 16px;
    text-transform: uppercase;
    font-style: italic;
    color: $pc-grey-medium;
    font-weight: bold;
}

.maps-popup_text {
    font-size: 12px;
    margin: 0 0 3px;
}

.maps-list_text {
    font-size: 14px;
    margin: 0 0 3px;
}

.maps-list_text--sm {
    font-size: 13px;
    margin: 0 0 3px;
}

.maps-popup_offset {
    padding-right: 5px;
}

.maps-list_section {
    position: relative;
    padding: 10px;
    border-bottom: $content-border;
    cursor: pointer;
    &.selected {
        background: $pc-grey-light;
    }
}

.maps-button_wrap {
    position: relative;
    padding: 20px 0 15px;
}

.maps_select-store {
    @include md-pc-red-button;
    clear: both;
}

.store-milage {
    position: absolute;
    bottom: 5px;
    right: 10px;
    font-size: 13px;
    color: $pc-grey-medium;
}

.selected-store {
    background: $pc-grey-light;
    padding: 10px;
    border-bottom: $content-border;
    cursor: pointer;
    &.empty {
        display: none;
    }
}

.maps-selected {
    border-bottom: $content-border;
    font-size: 16px;
    margin-top: 0;
    padding-bottom: 3px;
}

.maps-store-location {
    padding-top: 7px;
    color: $red-link;
    font-size: 14px;
    &.small {
        float: left;
        font-size: 12px;
    }
}

.no-stores_message {
    padding: 15px;
    font-size: 14px;
    text-align: center;
}

.store-add_error {
    padding: 0;
    margin-top: 11px;
    color: $red-link;
}


// Small devices (tablets, 768px and up)
@media only screen and (min-width: $pc-media-small) {
    .pc-v_sidebar {
        width: 30%;
    }

    .pc-v_content {
        width: 70%;
    }

    .store-list {
        border-bottom: none;
        border-right: $content-border;
    }

    .store-milage {
        position: relative;
        float: left;
        width: 100%;
        bottom: -15px;
        right: 0;
        margin-bottom: 20px;
    }
}


// Medium devices (desktops, 992px and up)
@media only screen and (min-width: $pc-media-medium) {
    .store-milage {
        position: absolute;
        float: left;
        width: auto;
        bottom: 5px;
        right: 10px;
        margin-bottom: 0;
    }
}

// Large devices (large desktops, 1200px and up)
@media only screen and (min-width: $pc-media-large) {
    .pc-v_sidebar {
        width: 25%;
    }

    .pc-v_content {
        width: 75%;
    }
}
