/***************************
Mixin Imports
****************************/

@mixin dark-gradiant {
	background: #5b5b5d;
	background: linear-gradient(to bottom, #616161 0%, #8f8f8f 50%, #484848 50%, #484848 100%);
	background: -ms-linear-gradient(top, #616161 0%, #8f8f8f 50%, #484848 50%, #484848 100%);
}

@mixin dark-button {
	background: #5b5b5d;
	background: linear-gradient(to bottom, #616161 0%, #737373 50%, #484848 50%, #484848 100%);
	background: -ms-linear-gradient(top, #616161 0%, #8f8f8f 50%, #484848 50%, #484848 100%);
	border: 1px solid #474748;
	a {
		color: $white-font;
	}
}

@mixin inputs {
	border: $input-border;
	border-radius: $sm-border-radius;
	//box-shadow: $input-shadow;
	//height: $input-height;
	padding: 8px;
	box-sizing: border-box;
	outline: none;
}

@mixin content-area {
	margin-bottom: 20px;
	background: $pc-content-bg;
	border-radius: $lg-border-radius;
	box-shadow: 0 0 3px #bbb;
	border: $content-border;
	//padding: 15px;
}

@mixin pc-text-shadow {
	text-shadow: 1px 1px 0 #000;
}

@mixin pc-text-shadow-white {
	text-shadow: 1px 1px 0 #fff;
}
@mixin pc-button {
	border-radius: $sm-border-radius;
	border: none;
	color: $white-font;
	padding: 15px;
	font-size: $button-font-size;
	@include pc-text-shadow;
	text-transform: uppercase;
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.45);
    text-decoration: none;
}
@mixin pc-red-button {
	@include pc-button;
	background: #c52825;
	background: linear-gradient(to bottom, #dc2825 0%, #c52825 100%);
}

@mixin pc-drkgrey-button {
	@include pc-button;
	background: #484848;
	background: linear-gradient(to bottom, #686868 0%, #484848 100%);
}

@mixin pc-grey-button {
	@include pc-button;
	@include dark-gradiant;
	display: inline-block;
	text-decoration: none;
	background: linear-gradient(to bottom, #8f8f8f 0%, #616161 100%);
}

@mixin md-pc-red-button {
	@include pc-red-button;
    font-weight: bold;
	padding: 8px 10px;
}

@mixin sm-pc-red-button {
	@include pc-red-button;
	padding: 3px 10px;
}

@mixin loader($border-width, $size) {
	margin: 60px auto;
	font-size: 10px;
	position: relative;
	// text-indent: -9999em;
	// border-top: 1.1em solid rgba(10, 10, 10, 0.3);
	// border-right: 1.1em solid rgba(10, 10, 10, 0.3);
	// border-bottom: 1.1em solid rgba(10, 10, 10, 0.3);
	border-top: $border-width solid rgba(10, 10, 10, 0.3);
	border-right: $border-width solid rgba(10, 10, 10, 0.3);
	border-bottom: $border-width solid rgba(10, 10, 10, 0.3);
	border-left: $border-width solid #000;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: spinner 1.1s infinite linear;
	animation: spinner 1.1s infinite linear;
	border-radius: 50%;
	// width: 12em;
	// height: 12em;
	width: $size;
	height: $size;

	&:after {
		border-radius: 50%;
		width: $size;
		height: $size;
	}

}
