.brand-scroll {
	overflow: auto;
}

.brands {
	box-sizing: border-box;
	padding: 17px;
	height: auto;
	background-color: #eee;
	font-size: 14px;
	font-weight: bold;
	border-bottom: 1px solid #bbb;
}

.brand-listing {
	padding: 0 15px;
}

.skip-to {
	clear: both;
	display: block;
	padding-bottom: 10px;
}

.st-seperator {
	display: none;
}

.brand-id {
	float: left;
    width: 100%;
	padding: 10px 0;
    clear: both;
}

.brands-by-letter {
	padding: 0;
	margin: 0;
	text-align: center;
}
.bbl-letter {
	display: inline-block;
	padding: 0 3px 5px 3px;

	a {
		font-size: 15px;
		text-decoration: none;
		color: $red-link;

		&:hover {
			text-decoration: underline;
		}
	}
}

.letter-key {
	box-sizing: border-box;
	// margin: 20px 15px;
	padding-bottom: 6px;
	width: 100%;
	clear: both;
	border-bottom: 1px solid $pc-grey;
}

.brand-desc {
	font-size: 15px;
	padding: 15px;
	// margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);

    ul {
    	padding-left: 20px;
    }
}

.manufacturer-image {
	display: block;
	float: right;
	padding: 10px;
	margin: -15px -15px 15px 15px;
	border-bottom: 1px solid #bbb;
	border-left: 1px solid #bbb;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);
}

// Small devices (tablets, 768px and up)
@media only screen and (min-width: $pc-media-small) {

	.bbl-letter {
		padding: 0 4px;

	}

}

// Medium devices (desktops, 992px and up)
@media only screen and (min-width: $pc-media-medium) {

	.brands {
		height: 53px;
	}

	.skip-to {
		display: inline-block;
		padding-bottom: 0;
	}

	.bbl-letter {
		padding: 0 3px;
	}

	.st-seperator {
		margin-left: 3px;
		display: inline;
	}

}

// Large devices (large desktops, 1200px and up)
@media only screen and (min-width: $pc-media-large) {

	.bbl-letter {
		padding: 0 6px;
	}

	.st-seperator {
		margin-left: 5px;
	}

}

// Extra large devices (extra large desktops, 2000px and up)
@media only screen and (min-width: $pc-media-largest) {

	.bbl-letter {
		padding: 0 8px;
	}

	.st-seperator {
		margin-left: 7px;
	}

}
