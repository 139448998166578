.pc-footer {
	background: url("/dist/img/footer-bg.png");
	clear: both;
	height: 150px;
	text-align: center;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;

	.footer-bar {
	    position: absolute;
	    content: "";
	    width: 100%;
	    height: 18px;
	    @include dark-gradiant;
	    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.45)
	}

	.footer-menus {
		padding: 40px 0 0;

		a {
			color: $white-font;
			text-decoration: none;
		}

		.main-footer-menu {
			padding-bottom: 10px;

			a {
				padding: 0 10px 0 10px;
				font-size: 13px;
			}
		}

		.second-footer-menu a {
			font-size: 60%;
		}
	
	}

	.copyright {
		color: $white-font;
		font-size: 60%;
	}
}