.pc-main-content {
	width: 100%;
	float: right;
	// border-left: $content-border;
	box-shadow: -10px 0px 50px rgba(0, 0, 0, 0.10);
	z-index:10;
	margin-right: -1px;
	//margin-bottom: -1px;
	padding-bottom: 9999px;
    margin-bottom: -10000px;
	clear: both;

    &.no-extend {
        padding-bottom: 0;
        margin-bottom: 0;
    }

	.pc-category {
		box-sizing: border-box;
		border-right: none;
		border-bottom: $content-border;
		text-align: center;
		padding-left: 0;
		padding-right: 0;

		a {
			text-decoration: none;
			font-weight: bold;
		}
	}

	.category-secondary-list {
	    float: left;
	    width: 100%;
	    margin: 25px 0;
	    min-height: 300px;
	}

	.pc-category-image {
		padding-top: 20px;
	}

	.category-link {
		display: block;
		vertical-align: bottom;
		padding: 40px 0 40px;
		color: $pc-font;
	}

	.browse-row {
		overflow: hidden;
		border-bottom: none;
	}

	.no-row {
		overflow: hidden;
	}

	.pc-sub-category {
		box-sizing: border-box;
		// padding: 15px;
		padding: 10px 15px;
		margin: 0;

		.list-unstyled {
			padding: 0;
			margin: 0;
		}

		a {
			text-decoration: none;
			color: $pc-font;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.pc-browse-noparts {
		color: #aaa;
		cursor: default;
	}
}

.section_container {
    padding: 15px;
}

/* All Selectors */
.plp-page {
	position: relative;
    background: #fff;
}

.search-page {
	position: relative;
}

.search-browse {
	background: #fff;
	z-index: 5;
}

.part-type-block {
	border-bottom: 1px solid $pc-grey;

	&:after {
		content: "";
		display: block;
		clear: both;
	}
}

.part-type-sub-block {
	margin-bottom: 20px;

	&:after {
		content: "";
		display: block;
		clear: both;
	}
}

.part-type-title {
	background: $pc-grey-light;
	border-bottom: 1px solid $pc-grey;
	padding: 15px;
	margin: 0 0 20px;
}

.part-type-subtitle {
	font-size: 17px;
	margin: 10px 15px 5px;
	padding-bottom: 10px;
	border-bottom: 1px solid $pc-grey;
}

.no-parts {
	font-weight: bold;
	padding: 15px;
}

.name {
	color: $pc-font;
	margin-bottom: 3px;

	a {
		color: $pc-font;
		text-decoration: none;

		&:hover {
			color: #666;
		}
	}
}

.price {
	font-family: $open-sans;
	font-size: 30px;
	font-weight: bolder;
	color: #c92825;
	padding-bottom: 8px;
}

.core-price {
	font-family: $open-sans;
	position: relative;
	top: -10px;
	font-size: 13px;
	color: #c92825;
	// padding-bottom: 1px;
}

.dollar-sign {
	@extend .price;
	margin-right: -5px;
}

.core-dollar-sign {
	margin-right: -2px;
}

.uom {
	@extend .price;
	font-size: 12px;
}

.available {
	font-weight: bold;
	margin-bottom: 5px;
}

.call-store {
	background-color: #2281b9;
	padding: 7px;
	font-size: 16px;
	font-weight: bold;
	color: #fff;
	text-transform: uppercase;
	@include pc-text-shadow;
}

/* PLP Selectors */

.plp-row-responsive {
	position: relative;
	display: block;
	//border-bottom: $content-border;
	overflow: hidden;
	//overflow: auto;

	&:after {
		clear: both;
		content: "";
		display: block;
	}
}

.pc-products {
	box-sizing: border-box;
	border-right: $content-border;
	width: 100%;
	float: left;
	border-bottom: $content-border;
}

.results-header {
	display: none;
	background: $pc-grey-light;
	border-bottom: $content-border;
	padding: 15px;
	font-size: 15px;
}

.rh-container {
	display: inline-block;
}

.pc-sort {
	display: block;
	float: none;
	font-size: 14px;
	padding: 5px 0 0 0;
	margin: 0 0 0 -9px;

	li {
		display: inline-block;
		list-style: none;
		padding-left: 9px;
	}

	.sort-divider {
		border-right: $content-border;
		padding: 0 3px;
	}
}

.sort-link {
	color: #666;
	text-decoration: none;

	&:hover {
		color: #111;
	}

	&.selected {
		color: #000;
		font-weight: bold;
	}
}

.fitment {
	padding: 15px;
	font-size: 15px;
	text-align: center;
	font-weight: bold;
	border-bottom: $content-border;
}

.select-store {
	display: none;
	box-sizing: border-box;
	position: relative;
	width: 100%;
	top: 0;
	left: 0;
	// background: $pc-background-red;
	background: #fff;
	// color: #fff;
	color: #000;
	padding: 15px;
	text-align: center;
	border-bottom: $content-border;
	z-index: 100;
	// @include pc-text-shadow;
}

.ss-button {
	display: block;
	max-width: 200px;
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
	text-decoration: none;
	text-align: center;
	@include md-pc-red-button;
}

.select-store-pdp {
	margin-top: 10px;
	font-weight: bold;

	.ss-button {
		font-weight: normal;
		margin-top: 8px;
	}
}

.plp-image {
	display: block;
	box-sizing: content-box;
	position: relative;
	text-align: center;
	vertical-align: middle;
	padding: 20px;
	border-bottom: 1px solid #e4e4e4;
	max-height: 150px;
	line-height: 150px;
	overflow: hidden;

	img {
		max-height: 150px;
		// max-width: 70%;
		max-width: 100%;
		vertical-align: middle;
	}
}

.click-image {
	line-height: 1;
	position: absolute;
	left: 0;
	bottom: 0;
	background-color: #fff;
	border-top: $content-border;
	border-right: $content-border;
	padding: 7px;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);
	display: block;
}

.zoom {
	text-decoration: none;
	color: $red-link;
	font-size: 15px;
	font-weight: bold;
}

.plp-info {
	padding: 10px;

	.line-item {
		font-size: 11px;
		color: #9c9c9c;
		padding-bottom: 5px;
	}

	.available {
		font-weight: bold;
		margin-bottom: 5px;
	}
}

.unavailable {
	@include pc-text-shadow;
	color: $white-font;
	font-weight: bold;
	text-transform: uppercase;
	background-color: #848484;
	font-size: 16px;
	padding: 7px;
	margin-top: 7px;
}

.multiple-apps {
	border-left: 2px solid $pc-grey;
	// padding: 1px 0 3px 5px;
	padding: 4px 0 1px 5px;
	margin-bottom: 8px;

	.attr-display {
		// text-indent: -7px;
		// margin-left: 7px;
		margin-left: 3px;
		margin-bottom: 5px;
	}
}

.ma-header {
	font-weight: bold;
	font-size: 14px;
	padding-bottom: 6px;
}

.attributes {
	padding-top: 7px;
	font-size: 12px;

	p {
		margin: 2px 0;
	}

	.attr-desc {
		font-weight: bold;
	}

	.attr-display {
		font-weight: normal;
		line-height: 16px;
	}
}

/* PDP Selectors */

.pdp-top-container {
	padding: 15px;
	clear: both;
}

.pdp-image {
	display: block;
	text-align: center;
	vertical-align: middle;
    box-sizing: border-box;
	margin: 0 20px 20px 0;
	max-height: 250px;
	line-height: 250px;
	width: 100%;
	cursor: pointer;
	//float: left;

	img {
		max-height: 250px;
		max-width: 95%;
		vertical-align: middle;
	}
}

.pdp-info {
	float:left;
	//width: 61%;
	width: 100%;

	.name {
		font-size: 21px;
	}

	.line-item {
		margin-bottom: 5px;
		padding-bottom: 5px;
		font-size: 13px;
		color: #9c9c9c;
		border-bottom: 1px solid $pc-grey;
	}

	// .price {
	// 	@extend .price;
	// }

	.available {
		font-weight: bold;
		margin-bottom: 15px;
	}

	.sl-button {
		position: relative;
		top: -2px;
		// margin-left: 5px;
	}
}

.pdp-images {
    clear: both;
    padding: 15px 15px 0 15px;
}

.pdp-thumbnail {
	display: inline-block;
	padding: 0;
    margin: 0 5px 0 0;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);
	border: 1px solid #aaa;
	height: 40px;
	width: 55px;
	text-align: center;
	line-height: 40px;
	cursor: pointer;
	transition: all .2s ease-in-out;

	&:hover {
		// transform: scale(1.05);
		border: 1px solid #999;
		box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.8);
		z-index: 100;
	}

	img {
		max-width: 45px;
		max-height: 30px;
		padding: 5px;
		text-align: center;
		vertical-align: middle;
	}
}

.qt-label {
	display: block;
	font-size: 12px;
	padding-bottom: 2px;
}

.pdp-tabs {
	padding: 0 15px 0 15px;
	border-bottom: 1px solid #bbb;
	clear: both;

	a {
		text-decoration: none;
		font-size: 14px;
		background-color: #ddd;
		color: $pc-font;
		margin-right: 6px !important;
		border-right: 1px solid #bbb !important;
		border-left: 1px solid #bbb !important;
		border-top: 1px solid #bbb !important;

	}
}

.pdp-tab-content {
	padding: 15px;
	margin-top: 15px;
	float: left;
	box-sizing: border-box;
	width: 100%;
	// This value can be removed once the tabs are added back in
	border-top: $content-border;

	.name {
		display: none;
		font-size: 21px;
	}

	.line-item {
		display: none;
		font-size: 11px;
		color: #9c9c9c;
		padding-bottom: 5px;
	}

	h4 {
		margin-top: 30px;
		font-weight: bold;
	}
}

.description {
	font-size: 15px;
	margin-bottom: 20px;

	ul {
		padding-top: 8px;
	}
}

.add-details {
	margin-top: 10px;
	margin-bottom: 20px;

	.attr {
		margin-bottom: 10px;
	}

	.attr-desc {
		margin-bottom: 7px;
		font-weight: bold;
	}

	.attr-display {
		font-weight: normal;
	}

	span {
		font-weight: bold;
	}

	p {
		font-size: 15px;
		margin-bottom: 4px;
	}
}

.add-details-header {
	margin-bottom: 20px;
}

.document-link {
	display: block;
	font-size: 15px;
	color: $red-link;
	text-decoration: none;
	margin-bottom: 7px;

	&:hover {
		text-decoration: underline;
	}
}

.attr-wrap {
	display: block;
	padding-bottom: 7px;

	label {
		// display: inline-block;
		box-sizing: border-box;
	}

	input {
		box-sizing: border-box;
		margin-right: 4px;
		position: relative;
    	top: -2px;
	}
}

.test {
	clear: both;
	color: #aaa;
}

input[name="quantity"] {
	// height: 22px;
	// width: 35px
	width: 50px;
}

/* Search content */
.search-not-found {
	padding: 15px;
	text-align: center;
    border-bottom: 1px solid #bbb;
}

.snf-bold {
	font-size: 22px;
	font-weight: bold;
}

.snf-normal {
	font-size: 14px;
}

.title.display,
.paginate {
	display: none;
}

.error-page {
	margin: 30px;
	text-align: center;
}

.ep-button-message {
	padding-bottom: 20px;
}

.store-info_section-left {
    float: left;
    width: 100%;
}

.store-info_section-right {
    float: left;
    width: 100%;
}
.info-map_wrap {
    padding: 15px;
}

.info-map {
    width: 100%;
    height: 300px;
    border: $content-border;
    margin-bottom: 20px;
}

.page-heading {
    font-size: 20px;
    margin: 20px 0 15px;
}

.page_mini-heading {
    font-size: 15px;
    margin: 10px 0;
}

.title-result {
    padding: 0;
    margin: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.search-query {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Small devices (tablets, 768px and up)
@media only screen and (min-width: $pc-media-small) {

	.pc-products {
		width: 50%;
		border-bottom: none;
		padding-bottom: 9999px;
		margin-bottom: -9999px;
	}

	.pc-main-content {

		.pc-category {
			border-right: $content-border !important;
			padding-bottom: 9999px;
			margin-bottom: -9999px;
		}

		.browse-row {
			border-bottom: $content-border;
		}

	}

	.pc-sort {
		display: inline-block;
		float: right;
		padding: 0;
		margin: 0;
	}

	.ss-button {
		margin-top: 0;
		display: inline-block;
	}

	.plp-row-responsive {
		width: 100%;
		border-bottom: $content-border;
	}

	.plp-image {
		//height: 200px;
		max-height: 200px;
		line-height: 200px;

		img {
			max-height: 200px;
		}
	}
    .store-info_section-left {
        width: 50%;
    }

    .store-info_section-right {
        width: 50%;
    }

    .pc-store-container {
        float: left;
        width: 100%;
        border-bottom: $content-border-light;
    }

    .search-query {
        display: inline;
    }

}

// Medium devices (desktops, 992px and up)
@media only screen and (min-width: $pc-media-medium) {

	.pc-main-content {
		width: 768px;
		clear: none;
		border-left: $content-border;
	}

	.pc-products {
		width: 50%;
		padding-bottom: 9999px;
		margin-bottom: -9999px;
	}

	.pc-content-container {
		clear: none;
	}

	.plp-row {
		position: relative;
		display: block;
		border-bottom: $content-border;
		overflow: hidden;

		&:after {
			clear: both;
			content: "";
			display: block;
		}
	}

	.plp-row-responsive {
		border-bottom: none;
		width: 50%;
		float: left;
		padding-bottom: 9999px;
		margin-bottom: -9999px;
	}

	.plp-image {
		max-height: 100px;
		line-height: 100px;
		padding: 10px;

		img {
			max-height: 100px;
		}
	}

	.click-image {
		display: none;
	}

	.pdp-image {
		width: 36%;
		float: left;
		max-height: 170px;
		min-height: 170px;
		line-height: 170px;

		img {
			max-height: 150px;
		}
	}

	.pdp-info {
		float:left;
		width: 61%;
	}

	.pdp-tab-content {

		.name {
			display: block;
		}

		.line-item {
			display: block;
		}
	}

}

// Large devices (large desktops, 1200px and up)
@media only screen and (min-width: $pc-media-large) {

	.pc-main-content {
		width: 901px;
		// width: 77.30%;
		clear: none;
	}

	.pc-products {
		width: 50%;
	}

	.pc-content-container {
		clear: none;
	}

	.plp-image {
		padding: 15px 15px;
	}

}

// Extra large devices (extra large desktops, 2000px and up)
@media only screen and (min-width: $pc-media-largest) {

	.pc-main-content {
		width: 1199px;
		clear: none;
	}

	.pc-products {
		width: 50%;
	}

	.pc-content-container {
		clear: none;
	}

	.plp-image {
		padding: 15px 15px;
	}

}
