.pc-main-menu {
	font-family: $open-sans;
	position: absolute;
	top: 0;
	left: 0;
	background: #000;
	color: #fff;
	width: 100%;
}

.promo-modified-main-menu {
	font-family: $open-sans;
	position: absolute;
	top: 0;
	left: 0;
	background: #000;
	color: #fff;
	width: 55%;
}
.search-container {
	display: none;
	box-sizing: border-box;
	white-space: nowrap;
	padding: 7px;
	width: 100%;
	position: relative;

	input {
		@include inputs;
		padding: 8px 40px 8px 8px;
		width: 100%;
	}

	.search-button {
        position: absolute;
		@include dark-button;
		border-radius: 0 $sm-border-radius $sm-border-radius 0;
		height: 33px;
		width: 35px;
		right: -5px;
		top: 5px;
	}
}

.navbar {
	border: none;
	min-height: 50px;
	width: auto;
	margin-bottom: 0;
	display: none;
	margin-bottom: -4px;
}

.dropdown-menu {
	position: relative;
	background-color: #000;
	border: none;
	border-radius: 0;
}

.navbar-toggle {
	padding: 20px 0;
	margin: 0;
	float: none;

	.sr-only {
		display: none;
	}

	.icon-bar {
		background-color: #fff;
	}
}

.button-container {
	width: 100%;
	height: 20px;
	display: block;
	text-align: center;
}

// Small devices (tablets, 768px and up)
@media only screen and (min-width: $pc-media-small) {

	.mean-bar {
		.navbar {
			width: 100%;
		}
	}

	.navbar-toggle {
		display: inline-block;
	}

	.navbar-collapse {

		&.collapse {
			display: none !important;
		}
	}

}

// Medium devices (desktops, 992px and up)
@media only screen and (min-width: $pc-media-medium) {

	.pc-main-menu {
		padding: 6px;
		position: relative;
		top: -19px;
		@include dark-gradiant;
		width: 100%;

		.inner-menu {
			border-radius: $sm-border-radius;
			background: $pc-content-bg;
		}

		.list-inline {
			margin: 0;
		}
	}

	.search-container {
		display: inline-block;
		width: 225px;
		margin-right: 6px;
		padding: 0 6px 0 0;

		.pc-search {
			height: 33px;
			width: 225px;
			margin: 5px 0 5px 5px;
			font-size: 14px;
		}
		// input::-webkit-input-placeholder,
		// input:-moz-placeholder,
		// input::-moz-placeholder,
		// input:-ms-input-placeholder {
		// 	color: #fff;
		// }
	}

	.button-container {
		display: none;
	}

	.s-image {

		img {
            position: absolute;
            top: 5px;
            left: 6px;
		}
	}

	.navbar {
		display: inline-block;
		box-sizing: border-box;
		position: relative;
		min-height: 0;
		margin: 0;
	}

	#menu-collapse {
	    display: inline-block !important;
	    padding: 0;
	    margin: 0;
	}

	.dropdown-menu {
		position: absolute;
		background-color: #fff;
		border: 1px solid #ccc;
		border-radius: 4px;

		.menu-link {
			line-height: 23px;
			font-weight: bold;
		}

		&.edge {

			&.first {
				position: absolute;
				right: 0;
				left: auto;
			}

			&.last {
				position: absolute;
				right: 0;
				left: auto;
			}
		}
	}

	.dropdown {
		display: inline-block;
		padding: 0;
		border-left: 1px solid #dadada;
		line-height: 44px;

		a {
			height: 100%;
			font-size: 14px;
			font-weight: bold;
			color: #000;
			text-decoration: none;
			padding: 0 9px 0 9px;
			margin: 0;
			line-height: 30px;
		}
	}
}

// Large devices (large desktops, 1200px and up)
@media only screen and (min-width: $pc-media-large) {

	.search-container {
		width: 251px;
	}

	.pc-search {
		width: 251px !important;
	}

	.dropdown-menu {

		&.edge {

			&.first {
				position: absolute;
				right: auto;
				left: 0;
			}
		}
	}

	.dropdown {

		a {
			font-size: 15px;
			padding: 0 20px 0 20px;
		}
	}
}

// Large devices (extra large desktops, 2000px and up)
@media only screen and (min-width: $pc-media-largest) {

	.search-container {
		width: 334px;
	}

	.pc-search {
		width: 334px !important;
	}

	.dropdown-menu.edge.first {
		position: absolute;
		right: auto;
		left: 0;
	}

	.dropdown.a {
		font-size: 15px;
		padding: 0 20px 0 20px;
	}
}
