.pc-secondary-menu {
	font-family: $open-sans;
	border-radius: $lg-border-radius;
	color: $white-font;
	width: auto;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.10);
	width: 100%;

	a {
		font-weight: 600;
		text-decoration: none;
	}

	span {
		display: block;
		font-size: 13px;
		@include pc-text-shadow;
		margin-bottom: -3px;
	}
}

.menu-container {
	position:relative;
	width: 100%;
	box-sizing: border-box;
	border-left: 1px solid $secondary_border_color;
	border-top: 1px solid $secondary_border_color;
	border-right: 1px solid $secondary_border_color;
	background-color: #d12726;
	background: linear-gradient(to bottom, #d12726 0%, #bd2323 100%);
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=#d12726, endColorstr=#bd2323)";
	border-radius: $lg-border-radius $lg-border-radius 0 0;
	overflow: hidden;

	.img {
		display: inline-block;
		height: 40px;
		max-height: 40px;
		float: left;

		img {
			vertical-align: middle;
			padding-right: 8px;
			padding-top: 5px;
			margin: auto;
			width: auto;

		}
	}

	.text {
		display: block;
		float: none;
		overflow: hidden;
	}

	p {
		text-transform: uppercase;
		font-weight: 600;
		@include pc-text-shadow;
	}

	.vehicle-select {
		border-bottom: 1px solid $secondary_border_color;
		padding: 4px 12px;
		height: 47px;

		&:hover {
			cursor: pointer;
			background-color: #d12726;
			border-radius: $lg-border-radius 0 0 $lg-border-radius;
			max-width: 100%;
		}

		.v-selected {
			display: inline-block;
			vertical-align: top;
			font-size: 13px;
			@include pc-text-shadow;
			// max-width: 200px;
			max-width: 100%;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;
			overflow: hidden;

			// transition-property: max-width;
		 //    transition-duration: 0.2s;
		 //    transition-timing-function: linear;
		    //transition-delay: 0.5s;
		}

	}

	.store-select {
		padding: 4px 12px;
		height: 47px;

		&:hover {
			cursor: pointer;
			background-color: #d12726;
		}

		.s-selected {
			display: inline-block;
			vertical-align: top;
			font-size: 13px;
			@include pc-text-shadow;
			// max-width: 200px;
			max-width: 100%;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;
			overflow: hidden;
		}
	}

	a {
		font-size: 16px;
		color: #fff;
		@include pc-text-shadow;
		font-weight: normal;
	}
}

.shopping-list {
	position: relative;
	@include dark-button;
	@include pc-text-shadow;
	// -ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=#d12726, endColorstr=#bd2323)";
	border-radius: 0 0 $lg-border-radius $lg-border-radius;
	padding: 4px 12px;
	height: 47px;

	&:hover {
		cursor: pointer;
	}

	.img {
		display:inline-block;
		height: 40px;
		max-height: 40px;
		float: left;

		img {
			vertical-align: middle;
			padding-right: 8px;
			padding-top: 5px;
			margin: auto;
			width: auto;

		}
	}


	.text {
		display: inline-block;
		float: left;
	    margin-top: -2px;
	    color: $white-font;
	}

	.sl-word {
		display: inline-block;
		line-height: 40px;
		font-size: 16px;
		font-weight: 600;
		text-transform: uppercase;
		vertical-align: middle;
	}

	.sl-total {
		display: inline-block;
		font-size: 16px;
		padding-left: 5px;
		position: relative;
		top: 2px;
	}

	.number {
		display: inline-block;
		font-size: 16px;
	    padding: 2px;
	    position: relative;
	    top: 1px;
	}
}

.vs-loader-container {
	display: none;
}

// Small devices (tablets, 768px and up)
@media only screen and (min-width: $pc-media-small) {

	.menu-container {
		border-right: 1px solid $secondary_border_color;
		margin-right: -1px;

		.vehicle-select {
			display: inline-block;
			box-sizing: border-box;
			float: left;
			border-bottom: none;
			border-right: 1px solid $secondary_border_color;
			width: 50%;
			height: 47px;

			.v-selected {
				max-width: 306px;
			}
		}

		.store-select {
			display: inline-block;
			box-sizing: border-box;
			float: left;
			border-right: none;
			//margin-left: -4px;
			width: 50%;
			height: 47px;
		}
	}

	.shopping-list {
		border-radius: 0 0 $lg-border-radius $lg-border-radius ;
		margin-right: 0;
		right: 0;
		text-align: center;

		.img {
			float: none;

			img {
				padding-right: 2px;
			}
		}

		.text {
			float: none;
		}
	}

}

// Medium devices (desktops, 992px and up)
@media only screen and (min-width: $pc-media-medium) {

	.pc-secondary-menu {
		float: right;
		border-radius: $lg-border-radius;
		display: inline-block;
		height: 47px;
		width: auto;
		margin-bottom: 0;
	}

	.menu-container {
		position: relative;
		display: inline-block;
		margin-bottom: 0;
		max-height: 47px;
		border-radius: $lg-border-radius 0 0 $lg-border-radius;
		border-bottom: 1px solid $secondary_border_color;
		width: auto;
		left: 4px;
	    margin-left: -4px;

		.vehicle-select {
			border-right: 1px solid $secondary_border_color;
			height: 46px;
			max-height: 46px;
			width: auto;
			min-width: 175px;

			.v-selected {
				max-width: 200px;
			}
		}

		.store-select {
			height: 46px;
			max-height: 46px;
			width: auto;
			min-width: 160px;
		}
	}

	.shopping-list {
		display: inline-block;
		// height: 37px;
		height: 47px;
		border-radius: 0 $lg-border-radius $lg-border-radius 0;
		margin-right: 0;
		right: 0;
		text-align: left;

		.img {
			float: left;

			img {
				padding-right: 8px;
			}
		}

		.text {
			float: left;
		}
	}

}
