.pc-header {
	background: #c32a26;
	background: linear-gradient(to bottom, #c32a26 0%, #921f1c 100%);
	height: auto;

	.header-bar {
		content: "";
		width: 100%;
		height: 18px;
		@include dark-gradiant;
	}

	.pc-logo {
		text-align: center;
        width: 100%;

		img {
			max-width: 200px;
			padding: 15px 0 15px;
		}
	}

	.login-greeting {
		padding: 15px 0;
		text-align: right;

		.greeting-msg {
			color: $white-font;
		}

		.logout-btn-wrapper {
			margin-top: 15px;
		}
	}
}

.search-container-responsive {
	border-radius: $sm-border-radius;
	@include dark-gradiant;
	position: relative;
	top: 39px;
	width: 100%;
	padding: 7px;

	.search-padding-responsive {
        position: relative;
		height: 46px;
		background: $pc-content-bg;
		border-radius: $sm-border-radius;
		padding: 4px 6px 6px 6px;
	}

	input {
		position: absolute;
		top: -10px;
		@include inputs;
		padding: 8px 40px 8px 8px;
		width: 100%;
	}

	.search-button-responsive {
		position: absolute;
		right: 0;
		top: -10px;
		@include dark-button;
		border-radius: 0 $lg-border-radius $lg-border-radius 0;
		height: 34px;
		width: 35px;
	}

	.s-image {
	    position: absolute;
	    top: 5px;
	    left: 5px;
	}
}

.search-responsive_wrapper {
    position: relative;
    float: left;
    width: 100%;
    padding: 0;
}

// Small devices (tablets, 768px and up)
@media only screen and (min-width: $pc-media-small) {

	.search-container-responsive {

		input {
			top: 2px;
		}

        .search-button-responsive {
            top: 2px;
        }
	}
}

// Medium devices (desktops, 992px and up)
@media only screen and (min-width: $pc-media-medium) {

	.pc-header {

		.header-bar {
			position: absolute;
		}

		.pc-logo {
			display: inline-block;
			width:auto;
			padding-top: 0;
			text-align: left;

			img {
				display: block;
				padding: 15px 0 35px;
			}
		}
	}

	.search-container-responsive {
		display: none;
	}
}
