.scroll-fixed {
	position: fixed !important;
	top: 15px;
}
.scroll-relative {
	position: relative !important;
	top: 0;
}

.scroll-absolute {
	position: absolute !important;
	bottom: 0 !important;
	right: 0 !important;
	top: initial;
}

.slist-container {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.slist-main-content {
	width: 100%;
	padding: 15px;
	box-sizing: border-box;
}

.goto-order-form {
	display: block;
	position: relative;
	font-family: "Open Sans",sans-serif;
	text-align: center;
	text-decoration: none;
	font-weight: bold;
	margin: 15px 15px 0 15px;
	@include sm-pc-red-button;
}

.store-total {
	margin-bottom: 12px;
	border: 1px solid #bbb;
	display: inline-block;
	box-sizing: border-box;
	width: 100%;

	.store {
		box-sizing: border-box;
		width: 100%;
		padding: 10px;

		span {
			font-weight: bold;
		}

		p {
			margin: 0 0 3px;
			font-size: 14px;
		}

		a {
			font-size: 13px;
			color: $red-link;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.st-name {
		font-weight: bold;
		display: inline;
	}
}

.est-total {
	box-sizing: border-box;
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
}

.tax {
	font-size: 10px;
	text-align: left;
}

.est-total-wrapper {

	h2 {
		margin: 5px 5px 5px 0;
	}
}

.total-price-container {
	display: inline-block;
}

.total-price {
	font-family: "Open Sans", sans-serif;
	font-weight: bold;
	color: $red-link;

	.dollar-sign {
		margin-right: 4px;
	}
}

.slist-items {
	border-top: 1px solid #bbb;
	border-left: 1px solid #bbb;
	border-right: 1px solid #bbb;
	margin-bottom: -3px;
	overflow: hidden;
}

.empty-list {
	padding: 20px;
	text-align: center;
	border-bottom: 1px solid #bbb;
}

.order-form-container {
	width: 100%;
	height: 100%;
	float: right;
}

.order-form,
.order-form-responsive {
	width: 100%;
	padding: 15px;
	position: relative;
	z-index: 999;

	h3 {
		font-size: 18px;
		margin-top: 0;
		margin-bottom: 15px;
	}

	input {
		width: 100%;
		margin-bottom: 15px;
	    box-sizing: border-box;

	    &[type="text"],
	    &[type="email"],
	    &[type="tel"] {
			border: 1px solid #848484;
	    }
	}

	input[type="checkbox"],
	input[type="radio"] {
		width: auto;
		border: none;
	}
}

.order-form-complete {
	box-sizing: border-box;
	padding: 15px 15px 30px 15px;

	.pc-red-button {
		padding-bottom: 15px;
	}
}

.of-perfered-form {
	border-bottom: $content-border;
}

.fs-text {
	font-size: 13px;
}

.fs-input {
	display: inline-block;
	float: left;
	padding-right: 18px;
	padding-left: 1px;

	font-size: 13px;

	input {
		display: inline-block;
		width: auto;
	}

	.fs-label {
		display: inline-block;
		padding-left: 5px;
		position: relative;
		top: 1px;

	}
}

.checkbox {
	font-size: 13px;
	margin-bottom: 10px;
	padding-top: 10px;
	padding-left: 20px;
	padding-bottom: 10px;

	input {
		top: 7px;
	}
}

.item-list {
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
	display: inline-block;
	width: 100%;
	// height: 100px;
	box-sizing: border-box;
	border-bottom: 1px solid #bbb;
	margin-bottom: -3px;

	.item {
		width: 100%;
		display: inline-block;
		float: left;
		padding: 15px;
		box-sizing: border-box;
	}

	.image-container {
		box-sizing: content-box;
		max-height: 150px;
		padding-bottom: 20px;
		width: 100%;
		display: block;
		float: left;
		text-align: center;

		img {
			max-height: 150px;
			max-width: 90%;
		}
	}

	.info {
		float: right;
		width: 100%;
	}

	.item-name {
		margin: 0 0 5px 0;
	}

	.quantity-info {
		display: inline-block;
		float: left;
		clear: both;
		margin-bottom: 15px;

		a {
			font-size: 14px;
		    color: $red-link;
		    text-decoration: none;

		    &:hover {
		    	text-decoration: underline;
		    }

		    &:active {
		    	color: #911b18;
		    }
		}
	}

	input[type="text"] {
		margin-right: 7px;
	}

	.dollar-sign {
		margin-right: 0;
	}

	.item-price {
		width: 100%;
		padding: 15px;
		box-sizing: border-box;
	}

	.price-container {
		// float: right;
		float: left;
		position: relative;
		clear: both;
	}

	.core-price-container {
		font-family: $open-sans;
		// float: right;
		float: left;
		clear: both;
		position: relative;
		top: -10px;
		font-size: 13px;
		color: $red-link;
		margin-bottom: 5px;

		.core-price {
			top: 0;
		}
	}

	.actual-price-container {
		position: relative;
		top: -5px;
		color: #888;
		// float: right;
		margin-bottom: 10px;
		float: left;
		font-size: 13px;
		clear: both;
		// text-transform: lowercase;

		.actual-price {
			// float: right;
			float: left;
			clear: both;
			// font-size: 16px;
			// font-weight: bold;

			// span {
			// 	font-size: 13px;
			// 	// font-weight: normal;
			// }
		}

		.actual-core {
			// float: right;
			float: left;
			clear: both;
			font-size: 13px;
		}
	}

	.line-item {
		font-size: 12px;
	    color: #9c9c9c;
	    padding-bottom: 5px;
	}

	.price {
		display: inline-block;
	}

	.for-vehicle {
		display: inline-block;
		font-weight: bold;
		font-size: 15px;
		margin-bottom: 5px;
	}

	.selected-vehicle {
		display: inline-block;
		font-size: 13px;
	}
}

.loader-container {
	display: none;
	position: absolute;
	left: 50%;
	top: 10%;
	margin-left: -60px;
	z-index: 100;
}

input.error {
	border: 1px solid red !important;
	margin-bottom: 5px !important;
}

label.error {
	position: relative;
    top: -4px;
    color: red;
    font-size: 14px;
}

.order-form-complete {
	font-family: $open-sans;
	font-size: 14px;

	a {
		text-decoration: none;
	}
}

.ofc-header {
	font-size: 16px;
	font-weight: bold;
	margin: 0 0 15px 0;
}

.ofc-name {
	font-weight: bold;
	margin: 0;
}

.ofc-store-info {
	margin-bottom: 15px;

	p {
		margin: 0;
	}
}

.ofc-profile-link {
    display: block;
    color: $red-link;
}

.ofc-pickup {
	margin: 0 0 15px 0;
	padding-bottom: 15px;
}

// Small devices (tablets, 768px and up)
@media only screen and (min-width: $pc-media-small) {

	.store-total {

		.store {
			width: 50%;
			float: left;
		}

		.est-total {
			width: 50%;
			float: left;
		}
	}

	.est-total-wrapper {
		text-align: right;
	}

	.tax {
		font-size: 10px;
		text-align: right;
		padding-left: 100px;
	}

	.item-list {

		.item {
			width: 50%;
			border-right: 1px solid #e4e4e4;
		}

		.list-price {
			display: inline-block;
			float: right;
		}

		.core-price-container {
			float: right;
		}

		.actual-price-container {
			float: right;

			.actual-price {
				float: right;
			}

			.actual-core {
				float: right;
			}
		}

		.quantity-info {
			float: right;
			clear: right;
		}
	}
}

// Medium devices (desktops, 992px and up)
@media only screen and (min-width: $pc-media-medium) {

	.slist-main-content {
		width: 650px;
		float: left;
		border-right: $content-border;
		padding: 15px 15px 10014px 15px;
		margin-bottom: -9999px;
	    box-shadow: 10px 0px 50px rgba(0, 0, 0, 0.10);
	}

	.slist-items {
		border-bottom: 1px solid #bbb;
	}

	.empty-list {
		border-bottom: none;
	}

	.goto-order-form {
		display: none;
	}

	.order-form-container {
		width: 318px;
		height: 100%;
		float: right;
	}

	.order-form {
		width: 318px;
	}

	.item-list {
		margin-bottom: -4px;

		.quantity-info {
			display: inline-block;
			float: right;
			clear: both;
			margin-bottom: 0;
		}

		.item {
			width: 60%;
			margin-bottom: -999px;
			padding-bottom: 999px;
		}

		.image-container {
			max-height: 85px;
			width: 35%;
			padding-bottom: 0;

			img {
				max-height: 85px;
				max-width: 90%;
			}
		}

		.info {
			float: right;
			width: 61%;
		}

		.for-vehicle {
			display: block;
		}

		.selected-vehicle {
			display: block;
		}

		.item-price {
			width: 40%;
			display: inline-block;
			float: left;
			padding: 15px;
		}
	}
}

// Large devices (large desktops, 1200px and up)
@media only screen and (min-width: $pc-media-large) {

	.slist-main-content {
		width: 825px;
	}

	.item-list {

		.quantity-info {
			float: none;
			clear: none;
		}

		.item {
			width: 50%;
		}

		.item-price {
			width: 50%;
		}
	}

	.order-form-container {
		width: 343px;
	}

	.order-form {
		width: 343px;
	}
}
