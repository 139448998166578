.pc-sidebar-left {
	position: relative;
    top: 1px;
	width: 100%;
	height: 100%;
	float: left;
	overflow: hidden;
}

.sidebar-container {
	// width: 50%;
	float: left;
	width: 100%;
	max-width: 100%;
	height: auto;
	// border-right: 1px solid $pc-grey;
}

.sidebar-image {
	width: 100%;
	height: auto;
	max-width: 100%;
    margin-bottom: -3px;

	img {
		max-width: 100%;
		border-bottom: 1px solid $pc-grey;
	}
}

.side-attributes {
	padding: 10px;
}

.attribute-title {
	max-width: 95%;
	font-size: 15px;
	margin: 0 0 3px 0;
}

.attribute-area,
.brands-area {
	width: 100%;
	float: left;
    border-bottom: 1px solid $pc-grey;
}

// .brands-area {
// 	float: right;
// }

.sb-close {
	font-size: 14px;
	font-weight: bold;
	position: relative;
	left: 3px;
	color: #d12726;
	z-index: 2;

	&:hover {
		cursor: pointer;
		color: #821c19;
	}

	a {
		color: #d12726;
		text-decoration: none;

		&:hover {
			color: #821c19;
		}
	}
}

.sb-no-link {
	font-size: 13px;
}

.sb-remove {
	position: absolute;
	padding-left: 8px;
	padding-top: 3px;
	visibility: hidden;
	opacity: 0;
    font-size: 12px;
    font-weight: normal;
    color: #821c19;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s;
    z-index: 1;

    &:hover {
    	// display: block;
    	color: #821c19;
    	opacity: 1;
    	cursor: pointer;
		// border-top: 1px solid #ddd;
		// border-right: 1px solid #ddd;
		// border-bottom: 1px solid #ddd;
		// border-radius: 2px;
    	// -webkit-transition: 0.2s;
	    // -moz-transition: 0.2s;
	    // transition: 0.2s;
    }
}

.sb-hover {
	display: inline;
	background: #fff;
	padding: 2px;

	&:hover > span {
		color: #821c19;
		
	}
}

.sb-attribute {
	font-size: 13px;

	&.non-retain {
		display: none;
	}
}

.side-brands {
	padding: 10px;
}

.sb-link {
	display: block;
	color: #000;
	font-size: 13px;
	margin-bottom: 5px;
	text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
}

.narrow-results-container {
	display: block;
	clear: both;
}

.ne-container {
	// clear: both;
	margin-bottom: 15px;

	&:after {
		display: block;
		content: "";
		clear: both;
	}
}

.side-narrow-results {
	position: relative;
	padding: 10px;
	font-size: 14px;
	border-bottom: 1px solid $pc-grey;

	&:after {
		display: block;
		content: "";
		clear: both;
	}
}

.nr-header {
	font-size: 15px;
	margin: 0 0 10px 0;
	padding-bottom: 6px;
	border-bottom: 1px solid $pc-grey;
}

.snr-link-price,
.snr-link-brand,
.snr-link-category {
    float: left;
    clear: both;
    text-decoration: none;
    color: #000;
    margin: 0 0 10px;

    &:hover {
    	text-decoration: underline;
    	cursor: pointer;
    }

    &.selected {
    	font-weight: bold;
    	text-decoration: none;
    	cursor: text !important;
    }
}

.remove-snr {
	font-size: 14px;
    font-weight: bold;
    position: relative;
    left: 5px;
    color: #d12726;
    cursor: pointer;

    &:hover {
    	color: #821c19;
    }
}

// Small devices (tablets, 768px and up)
@media only screen and (min-width: $pc-media-small) {

	.sidebar-container {
		width: 50%;
		max-width: 50%;
		border-right: 1px solid $pc-grey;
	}

	.attribute-area,
	.brands-area {
		width: 50%;
		border-right: 1px solid $pc-grey;
		border-bottom: none;
		padding-bottom: 9999px;
	    margin-bottom: -9999px;
	}

	.brands-area {
		float: right;
	}

	.narrow-results-container {
		float: left;
		width: 50%;
	}

}

// Medium devices (desktops, 992px and up)
@media only screen and (min-width: $pc-media-medium) {

	.pc-sidebar-left {
		width: 200px;
		top: 0;
	}

	.sidebar-container {
		float: left;
		width: 100%;
		max-width: 100%;
		height: auto;
		border-right: none;
	}

	.attribute-area,
	.brands-area {
		width: 100%;
		float: left;
	    border-bottom: 1px solid $pc-grey;
	    border-right: none;
	    padding-bottom: 0;
	    margin-bottom: 0;
	}

	.narrow-results-container {
		float: left;
		width: 100%;
	}

}

// Large devices (large desktops, 1200px and up)
@media only screen and (min-width: $pc-media-large) {

	.pc-sidebar-left {
		width: 267px;
		// width: 22.75%;
	}

	.attribute-title {
		max-width: 90%;
	}

}

// Extra Large devices (extra large desktops, 1600px and up)
@media only screen and (min-width: $pc-media-largest) {

	.pc-sidebar-left {
		width: 347px;
	}

	.attribute-title {
		max-width: 90%;
	}

}
