// Fonts
$open-sans: 'Open Sans', sans-serif;

// Backgrounds
$pc-background: #1a1a1a;
$pc-background-red: #c32a26;

// Font variables
$pc-font: #000;
$white-font: #fff;
$button-font-size: 16px;
$red-link: #c92825;

// Main content background
$pc-content-bg: #fff;

// Default site radius
$lg-border-radius: 5px;
$sm-border-radius: 3px;

// Default Input values
$input-border: 1px solid #848484; //#bababa;
$input-shadow: inset 3px 3px 4px rgba(0, 0, 0, 0.15);
$input-height: 32px;


// Various other colors
$pc-grey: #d8d8d8;
$pc-grey-light: #eeeeee;
$pc-grey-medium: #666;
$pc-grey-dark: #484848;

// Content variables
$content-border: 1px solid #bbb;
$content-border-light: 1px solid $pc-grey;

// Parts City media query sizes
$pc-media-smallest: 400px;
$pc-media-smaller: 600px;
$pc-media-small: 768px;
$pc-media-medium: 992px;
$pc-media-large: 1200px;
$pc-media-largest: 2000px;

// Secondary Menu Variables
$secondary_border_color: #821c19;
