.home_section-left {
    width: 100%;
    float: left;
}

.home_section-right {
    position: relative;
    width: 100%;
    margin-bottom: 125px;
    .row {
        margin-left: 0;
        margin-right: 0;
    }
}

.pc-home-gap {
    margin-bottom: 20px;
    min-height: 100px;
}

.brands-index {
    margin-top: 40px;
    margin-bottom: 20px;
    min-height: 0;
}

.home-text {
    font-size: 14px;
    line-height: 22px;
    color: $pc-grey-dark;
}

.home-section-padding {
    padding: 15px;
}

.home-content {
    width: 100%;
}

.home_img-content {
    float: left;
    width: 100%;
    img {
        width: 100%;
    }
}

.header-button-container {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: -40px;
}
.current-ad-link {
    display: block;
    height: 47px;
    width: 100%;
    color: #fff;
}
.current-ad-link-text {
    justify-content: center;
    color: #fff;
    line-height: 47px;
    vertical-align: middle;
}

// Small devices (tablets, 768px and up)
@media only screen and (min-width: $pc-media-small) {
    .home_section-right {
        float: left;
        .row {
            margin-left: -15px;
            margin-right: -15px;
        }
    }
    .home_side-content {
        float: left;
        width: 46.1%;
        margin-left: 15px;
        margin-right: 15px;
    }
}


// Medium devices (desktops, 992px and up)
@media only screen and (min-width: $pc-media-medium) {
    .brands-index {
        margin-top: 0;
    }
    .home_section-left {
        width: 655px;
        margin-bottom: 125px;
    }

    .home_section-right {
        float: right;
        margin-left: 0;
        margin-right: 0;
        width: 30%;
        .row {
            margin-left: 0;
            margin-right: 0;
        }
    }
    .header-button-container {
        display: inline-block;
        float: right;
        margin-top: 25px;
    }
    .current-ad-link {
        display: inline-block;
        width: 160px;
    }
    .home_side-content {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
}

// Large devices (large desktops, 1200px and up)
@media only screen and (min-width: $pc-media-large) {
    .home_section-left {
        width: 855px;
    }

    .home_section-right {
        width: 25%;
    }
}
